/* Modular Scale 1em
   You can use different modular scales with *Simplicity*
   Just configure the modular scale in _config.yml you want to use.

   Font Sizes
------------------------------------------------------------------- */

$base-font-size                 : 18px;
$base-font-size-medium-screen   : 22px;
$base-font-size-large-screen    : 26px;
$font-size-p                    : 1em;
/* h4, h5, h6: 1 em, h3: 1.25em, then factor 1.2 to the next higher level
earlier tries were 
factor 1.15
factor 1.12 to the next higher level, i. e. h2: 1.4, h1: 1.568
factor 1.12, i. e. h2: 1.4375, h1: 1.6531)
changed 14.12.2020 --rw:
Archive: changed 23.05.2018 --rw:
$font-size-h1                   : 1em;
$font-size-h1                   : 1.424em;
$font-size-h1                   : 1.6531em;
$font-size-h1                   : 1.568em;
$font-size-h1                   : 1.505em;
$font-size-h1                   : 1.653em;  */ 
$font-size-h1                   : 1.800em; 

/* changed 23.05.2018 --rw:
$font-size-h2                   : 1em;
$font-size-h2                   : 1.266em;
$font-size-h2                   : 1.4375em;
$font-size-h2                   : 1.4em;  
$font-size-h2                   : 1.344em;
$font-size-h2                   : 1.438em; */ 
$font-size-h2                   : 1.5em;

/* changed 23.05.2018 --rw:
$font-size-h3                   : 1em;
$font-size-h3                   : 1.125em;
$font-size-h3                   : 1.25em; 
$font-size-h3                   : 1.2em; */ 
$font-size-h3                   : 1.25em; 

$font-size-h4                   : 1em;
$font-size-h5                   : 1em;
$font-size-h6                   : 1em;
/* changed 15.05.2018 --rw: 
$font-size-small                : 1em; */
$font-size-small                : .75em; 
