/* Body
------------------------------------------------------------------- */

body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    color: $text-color;
    -webkit-text-size-adjust: 100%;
    // http://blog.typekit.com/2014/02/05/kerning-on-the-web/
    text-rendering: optimizeLegibility;
    // http://practice.typekit.com/lesson/caring-about-opentype-features/
    font-kerning: normal;
    font-variant-ligatures: common-ligatures, contextual;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "alt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
}


/* Headlines
------------------------------------------------------------------- */

h1, h2, h3, h4, h5, h6 {
  font-family: $header-font-family;
  font-weight: $font-weight-normal;
  padding: 0;
}

h1 {
  font-size: $font-size-h1;
/* changed 23.05.2018 --rw: 
  text-transform: uppercase; */
  font-weight: $font-weight-bold;
  letter-spacing: 1px;
  margin-top: 0;
}
h2 {
  font-size: $font-size-h2;
/* added font-weight-bold 14.12.2020 --rw: 
   removed it again 17.12.2020 --rw: 
  font-weight: $font-weight-bold; */
/* changed 23.05.2018 --rw: 
  text-transform: uppercase; */
  letter-spacing: 1px;
/* changed 28.06.2018 --rw: 
  margin-top: 2em; */
  margin-top: 1em;
// added clear to avoid h2 to start within image or image to overlap h2, respectively 23.10.2020 in digi-oek.ch, added here 09.06.2022 --rw: 
  clear:both;
}
h3 {
  font-style: italic;
  font-size: $font-size-h3;
/* added font-weight-bold 14.12.2020 --rw: */
  font-weight: $font-weight-bold;
/* changed 28.06.2018 --rw: 
  margin-top: 2em; */
  margin-top: 1em;
}
h4 {
  font-size: $font-size-h4;
  font-style: italic;
/* changed 28.06.2018 --rw: 
  margin-top: 2em; */
  margin-top: 1em;
}
h5 {
  font-size: $font-size-h5;
}
h6 {
  font-size: $font-size-h6;
}



/* Customize Typography
------------------------------------------------------------------- */

p {
/* If automatic hyphenation is wanted then uncomment this 17.08.2018 --rw: 
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  -ms-word-break: break-all;
*/
  /* Non standard for webkit */
  word-break: break-word;
}



/* Images
------------------------------------------------------------------- */

img {
  border-radius: $global-radius;
  max-width: 100%;
}

.alignleft,
.left {
  float: left; 
  margin: .35em 1em 0 0;
}
.alignright,
.right {
  float: right;
  margin: .35em  0 0 1em;
}
.aligncenter,
.center {
  display: block;
  margin: .25em auto .25em;
}

figure {
  margin: 0 0 1em 0;
}
#masthead-with-background-color figure,
#masthead-with-pattern figure {
  margin: 0;
}

figcaption {
/*  was: 
color: $grey-color-light; 
which is too light, change similar to digi-oek.ch, same for masthead-caption below
27.11.2020 --rw: 
*/
  color: $grey-color;
  font-size: $font-size-small;
  padding-top: .25em;
  text-align: right;

  a, a:link,
  .masthead-caption a, .masthead-caption a:link {
    border-bottom: 1px dotted $grey-color-light;
    color: $grey-color;
  }

  a:hover,
  .masthead-caption a:hover {
    border-bottom-style: solid;
  }
}

.masthead-caption {
  padding-right: .5em;
  text-align: right;
  /* Teaser-date, but teaser title text see /_sass/05_layout/_toc.scss 
  	  -> ul.toc li a span 01.12.2018 --rw:   */
/*  was: 
color: $grey-color-light; 
which is too light, change similar to digi-oek.ch, same for figcaption above
27.11.2020 --rw: 
*/
  color: $grey-color;
  font-size: $font-size-small;
  padding-top: .25em;
}



/* Tables
------------------------------------------------------------------- */

td {
  vertical-align: top;
}



/* Code
------------------------------------------------------------------- */

pre {
  font-size: .75em;
  font-family: $font-family-monospace;
  font-weight: normal;
  overflow: auto;
  padding: 5px;
  border-radius: $global-radius;
}
pre code {
  border: 0;
}

code {
  font-family: $font-family-monospace;
  line-height: 1.5;
}

p code {
  background: $ci-4;
  box-decoration-break: clone;
  padding: 0 .25em;
}



/* Lists
------------------------------------------------------------------- */

ul, ol {
  margin-left: 1.25em;
  padding: 0;
}
li {
  margin-left: 0;
}

.no-bullet {
  list-style: none;
  margin-left: 0;
}

li {
  > ul,
  > ol {
   margin-bottom: 0;
 }
}

dl {

}
dt:first-child {
  padding-top: 0px;
}
dt {
  font-weight: bold;
  padding-top: 30px;
}
dd {
}
article dl dt   { line-height: 1.3; }
article dl dd   { line-height: 1.6; margin-bottom: rem-calc(12); margin-left: rem-calc(24); }



/* Quotes
------------------------------------------------------------------- */

blockquote {
/* 
Margin top is too large, reduce to 1 em 29.10.2020 --rw: 
  margin: 2em 0em 2em 2em;
*/
  margin: 1em 0em 2em 2em;
/* no uppercase 23.04.2020 --rw: 
  text-transform: uppercase;
*/ 
  color: $ci-2;
  font-style: italic;
  p {
    font-style: italic;
  }
  a {
    color: $ci-2 !important;
    border-color: $ci-2 !important;
  }
  cite:before {
    content:"\2014 \0020";
    margin-left: .25em;
  }
}
cite {
  padding-top: 5px;
}
.frontpage blockquote {
  margin: 0;
}

button, .button {
  letter-spacing: 1px;
}

mark {
  background-color: mix(#fff, $warning-color, 50%);
  box-decoration-break: clone;
  padding: 0 .5em;
}
kbd {
  background: $background-light-color;
  border-radius: $global-radius;
  padding:0 0.25em;
}


/* Typography
------------------------------------------------------------------- */

hr {
  border: 0;
  border-top: dotted 1px  $line-color;
}

// Break with clear attribute (br clear =)  is deprecated in HTML5, use CSS to ensure that a line break appearing in text wrapped around an image causing the next bit of content after the <br> to appear below the image. Use in HTML: <br class="clear">.  09.11.2020 in digi-oek.ch, added here 09.06.2022 --rw:  
br.clear {
  clear: both; 
}


// The ::selection CSS pseudo-element applies rules to the portion of
// a document that has been highlighted by the user.

::selection {
  background: $ci-4;
}
