/* Color Variables
-------------------------------------------------------------------

- Corporate Identity Colorpalette
- Alerts
- Grey Scale
- Basics
- Code

*/


/* Corporate Identity Colorpalette
------------------------------------------------------------------- */

/* $ci-1: #900055;		// is used as the primary branding color – for example for links.
Changed 28.06.2018 --rw: */
// Falu red:  
$ci-1: #801818; 
/* ci-2 is used e. g. in #tnav a:hover. Since ci-1 is changed, used original ci-1 instead 20.07.2018 --rw
$ci-2: #d4007a;		// is used as the secondary branding color.  */
/* Was a bit too brownish, test a few complementary, monochromatic etc. colours from colorhexa.com 15.12.2020 --rw  
$ci-2: #900055;		// is used as the secondary branding color.
$ci-2: #b77033;		// dig-oek.ch -- is used as the secondary branding color.
$ci-2: #7b4b22;		// is used as the secondary branding color.
$ci-2: #337ab7;		// is used as the secondary branding color.
$ci-2: #2d6da3;		// is used as the secondary branding color.
$ci-2: #285f8f;		// is used as the secondary branding color. */
$ci-2: #22527b;		// is used as the secondary branding color.
$ci-3: #fabb00;
$ci-4: #f5b6c9;
/* Added 13.07.2018 --rw: 
Dark coral cd5b45: 
$ci-5: #cd5b45; 
Terra cotta e2725b: 
added specifically for tag cloud */
$ci-5: #e2725b;
/* Add ci-6: Background-color for #tnav a:hover was originally ci-1, a bit too dark, try Dark coral cd5b45: 
   This is a very prominent colour since hovering over the site title has this background.  20.07.2018 --rw */
$ci-6: #cd5b45;



/* Alerts
------------------------------------------------------------------- */

$alert-color:   #aa0132;
/* Test 27.04.2018 --rw:  
$alert-color:   #111; */
$success-color: #6a9519;
$warning-color: #e05a10;
/* for alert-box info, try a less intense colour 19.12.2020 --rw: 
$info-color:    #0085ba;
Which is: Pure (or mostly pure) blue, i. e. #0085ba Monochromatic Color 2 steps to the right, according to https://www.colorhexa.com/0085ba 
$info-color:    #00a9ed;
#0085ba Monochromatic Color 1 steps to the right, according to https://www.colorhexa.com/0085ba  
$info-color:    #0097d4;
Try some other colours: 
$info-color:    #318ce7;
$info-color:    #ace5ee;
... all not satisfactory, so back to original:  */ 
$info-color:    #0085ba;



/* Grey Scale
------------------------------------------------------------------- */

/* Try normal grey, i. e. a bit darker than #828282 
Strange: https://www.colorhexa.com/a9a9a9 it's called gray, but https://www.colorhexa.com/color-names says Dark gray
10.12.2020 --rw: 
$grey-color:       #828282;
*/ 
$grey-color:       #a9a9a9;
/* Light grey #E4E4E4 is a bit too light, see https://www.colorhexa.com/e4e4e4
change to something darker 27.11.2020 --rw: 
$grey-color-light: #E4E4E4; 
Try 1a: Cadet grey #91a3b0 , s https://www.colorhexa.com/91a3b0, too dark because of background use with text: 
$grey-color-light: #91a3b0; 
Try 1b: closest websafe colour #999999: 
$grey-color-light: #999999;
Try 2a: lightest Monochromatic Color of Cadet grey, #bec8d0: 
$grey-color-light: #bec8d0;
Try 2b: lightest Monochromatic Color of Cadet grey, closest websafe colour #cccccc 
$grey-color-light: #cccccc; 
Final 3: leave as was originally, change specifics such as figcaption or .masthead-caption in /_sass/04_typography/_general_typography.scss from grey-color-light to grey-color, similar to digi-oek.ch 
27.11.2020 --rw: 
*/
$grey-color-light: #e4e4e4; 
$grey-color-dark:  #4b4b4d;



/* Basics
------------------------------------------------------------------- */

$text-color:          #111;
$background-color:    #fefefe;
// Following Color used for .toc, a:visted;
$background-light-color: $grey-color-light;
/* Changed for digi-oek.ch, but lighter background color 
	01.12.2018 --rw: 
$line-color:       			$grey-color-light; */ 
$line-color:       			$grey-color;
/* New for digi-oek.ch, background color for certain backgrounds like
	/_sass/05_layout/_navigation.scss > .tnav-children > background 
	i. e. .tnav-children in /_sass/05_layout/_navigation.scss uses now newly created bgcolor
	01.12.2018, wuergler-cons 10.12.2020 --rw: */ 
$bgcolor:       $grey-color-light;




/* Topbar Navigation
------------------------------------------------------------------- */

$topbar-navigation-background-color:    $background-color;
$topbar-navigation-border-bottom:       1px solid $line-color;



/* Footer (Meta and Site-Footer)
------------------------------------------------------------------- */

$footer-color:    		#828282;
